import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import DynamicSentence from "../components/dynamic-sentence";
import PlayerDefine from "../components/player-define";
import PlayerCopy from "../components/player-copy";
import EndGame from "../components/end-game";

import TimerContext from "../context/time-context";
import PlayerContext, { initialPlayers } from "../context/player-context";
import GameContext from "../context/game-context";
import home from "../img/yellowhome.svg";
import restart from "../img/yellowrestart.svg";

const Header = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  width: 95%;
  justify-content: space-between;
  height: 25px;
  padding: 1% 2%;
`;
const Body = styled.div`
  position: absolute;
  top: 75px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  align-items: center;
  gap: 20px;
`;
const BtnContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  justify-content: space-evenly;
  animation: opacityAnim 1.1s ease-out 0.1s backwards;
`;
const CustomBtn = styled.button`
  height: 75%;
  width: 25%;
  background: #bff90b;
  color: #090d0d;
  font-size: 150%;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  font-family: "ClashGrotesk-Semibold";
  animation: ${({ $anim }) => ($anim ? "pulsate 0.2s linear" : "")};
`;
const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: ${({ $xl }) => ($xl ? "70%" : "55%")};
  align-items: center;
  overflow: auto;
  scroll-behavior: smooth;
  gap: 15px;
`;
const HomeImg = styled.img`
  height: 50px;
  animation: opacityAnim 1s linear backwards;
`;
const RestartImg = styled.img`
  height: 40px;
  padding-top: 5px;
  animation: opacityAnim 1s linear backwards;
`;

function Game() {
  const navigate = useNavigate();

  const { timer, setTimer } = useContext(TimerContext);
  const { players, setPlayers } = useContext(PlayerContext);
  const { game, setGame } = useContext(GameContext);

  const [action, setAction] = useState("define");
  const [currPlayerId, setCurrPlayerId] = useState(0);
  const [playersToCopy, setPlayersToCopy] = useState([]);
  const [eliminatedPlayers, setEliminatedPlayers] = useState([]);
  const [noBtnAnim, setNoBtnAnim] = useState(false);
  const [yesBtnAnim, setYesBtnAnim] = useState(false);

  function restartGame() {
    setPlayers(
      players.map((p) => ({
        name: p.name,
        letter: "",
        redo: game.allowRedo,
        nbOfTry: game.isHard ? 1 : game.nbOfTry,
        stats: initialPlayers.players[0].stats,
      }))
    );
    setTimer({ isActive: true, isPaused: false, timeCounter: 0 });
    setGame({
      ...game,
      isRunning: true,
      targetWord: game.targetWord ? game.targetWord : game.defaultTargetWord,
      nbOfTry: game.isHard ? 1 : game.nbOfTry,
    });
    setAction("define");
    setCurrPlayerId(0);
    setPlayersToCopy([]);
    setEliminatedPlayers([]);
  }
  function addLetter(players, idx) {
    return players.map((p, i) =>
      i === idx
        ? { ...p, letter: p.letter + game.targetWord[p.letter.length] }
        : p
    );
  }
  function incrementStat(players, idx, stat) {
    return players.map((p, i) =>
      i === idx ? { ...p, stats: { ...p.stats, [stat]: p.stats[stat] + 1 } } : p
    );
  }
  function handleNo() {
    if (game.isHard) {
      handleNoHardMode();
    } else {
      handleNoEasyMode();
    }
  }
  function handleNoHardMode() {
    const letteredPlayers = addLetter(players, currPlayerId);
    const statedPlayers = incrementStat(
      letteredPlayers,
      currPlayerId,
      "nbFailedDef"
    );

    const playerNextLetter = statedPlayers[currPlayerId].letter;
    if (playerNextLetter === game.targetWord) {
      handleGameEnd(statedPlayers);
    } else {
      handleNextPlayer(statedPlayers);
    }
  }
  function handleGameEnd(statedPlayers) {
    const nextPlayers = statedPlayers.filter((p, i) => i !== currPlayerId);

    if (nextPlayers.length === 1) {
      endGame(statedPlayers);
    } else {
      const elimPlayer = statedPlayers[currPlayerId];
      const eliminated = [...eliminatedPlayers, elimPlayer];
      setPlayers(nextPlayers);
      setEliminatedPlayers(eliminated);
      if (currPlayerId < nextPlayers.length || currPlayerId === 0) {
      } else {
        setCurrPlayerId((currPlayerId + 1) % nextPlayers.length);
      }
    }
  }
  function handleNextPlayer(statedPlayers) {
    setPlayers(statedPlayers);
    setCurrPlayerId((currPlayerId + 1) % players.length);
  }
  function handleNoEasyMode() {
    const nextPlayers = incrementStat(players, currPlayerId, "nbFailedDef");
    setPlayers(nextPlayers);
    setCurrPlayerId((currPlayerId + 1) % players.length);
  }
  function endGame(statedPlayers) {
    setPlayers([...statedPlayers, ...eliminatedPlayers]);
    setGame({ ...game, isRunning: false });
    setTimer({ ...timer, isPaused: true, isActive: false });
  }
  function handleYes() {
    const nextPlayers = incrementStat(players, currPlayerId, "nbDef");
    setAction("copy");
    setPlayersToCopy(players.filter((p, index) => index !== currPlayerId));
    setPlayers(nextPlayers);
  }

  useEffect(() => {
    let interval = null;

    if (timer.isActive && !timer.isPaused && game.isRunning) {
      interval = setInterval(() => {
        setTimer({ ...timer, timeCounter: timer.timeCounter + 1 });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer.isActive, timer.isPaused, timer.timeCounter]);

  useEffect(() => {
    if (playersToCopy.length === 0 && action === "copy") {
      setAction("define");
      setCurrPlayerId(
        currPlayerId >= players.length ? 0 : (currPlayerId + 1) % players.length
      );
    }
  }, [JSON.stringify(playersToCopy), players]);

  if (!game.isRunning) {
    return (
      <div className="pagewrapper">
        <EndGame restartFunc={restartGame} />
      </div>
    );
  }
  return (
    <div className="pagewrapper">
      <Header>
        <HomeImg onClick={() => navigate("/")} src={home} alt="home" />
        <RestartImg onClick={() => restartGame()} src={restart} alt="restart" />
      </Header>
      <Body>
        <DynamicSentence
          name={action === "define" ? players[currPlayerId].name : null}
          action={action}
        />
        {action === "define" && (
          <BtnContainer>
            <CustomBtn
              onClick={() => {
                handleNo();
                setNoBtnAnim(true);
              }}
              onAnimationEnd={() => setNoBtnAnim(false)}
              $anim={noBtnAnim}
            >
              No
            </CustomBtn>
            <CustomBtn
              onClick={() => {
                setTimeout(() => {
                  handleYes();
                }, 200);
                setYesBtnAnim(true);
              }}
              onAnimationEnd={() => setYesBtnAnim(false)}
              $anim={yesBtnAnim}
            >
              Yes
            </CustomBtn>
          </BtnContainer>
        )}
        <PlayerContainer $xl={action === "copy"}>
          {action === "define" ? (
            <>
              {players.map((p, i) => (
                <PlayerDefine
                  key={i}
                  idx={i}
                  player={p}
                  currPlayerId={currPlayerId}
                  action={action}
                />
              ))}
            </>
          ) : (
            <>
              {playersToCopy.map((p, i) => (
                <PlayerCopy
                  key={i}
                  idx={i}
                  player={p}
                  currPlayerId={currPlayerId}
                  playersToCopy={playersToCopy}
                  eliminatedPlayers={eliminatedPlayers}
                  setPlayersToCopy={setPlayersToCopy}
                  setEliminatedPlayers={setEliminatedPlayers}
                />
              ))}
            </>
          )}
        </PlayerContainer>
      </Body>
    </div>
  );
}

export default Game;
